<template>
  <b-modal :title="notification.title" ok-only centered v-model="isShow">
    <div>
      <a
        v-if="notification.related_url"
        :href="notification.related_url"
        target="_blank"
        >參考網址：{{ notification.related_url }}</a
      >
    </div>
    <div style="white-space: pre-line">{{ notification.content }}</div>
  </b-modal>
</template>

<script>
export default {
  name: "NotificationModal",
  props: ["show", "notification"],
  computed: {
    isShow: {
      get() {
        return !!this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
};
</script>
